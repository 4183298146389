import React from "react";
import { useNavigate } from "react-router-dom";
import { db, doc, getDoc, setDoc } from "../../../firebaseConfig";
import "./ConnectAdmin.css";

const ConnectAdmin = () => {
  const navigate = useNavigate();

  const handleLoginWithPhantom = async () => {
    if ("solana" in window && window.solana.isPhantom) {
      try {
        const response = await window.solana.connect();
        const publicKey = response.publicKey.toString();
        const walletRef = doc(db, "wallets", publicKey);
        const walletSnap = await getDoc(walletRef);
        const timestamp = new Date().toISOString();

        if (!walletSnap.exists()) {
          await setDoc(walletRef, { walletId: publicKey, lastLogin: timestamp, walletType: "Solana" });
          console.log("Wallet ID saved to Firestore:", publicKey);
        } else {
          console.log("Wallet data retrieved:", walletSnap.data());
        }

        const userDocRef = doc(db, "users", publicKey);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists() && userDoc.data().profile.role === "admin") {
          console.log("Admin role verified:", userDoc.data().profile.username);
          navigate(`/dashboard-admin/?walletId=${publicKey}`);
        } else {
          console.error("User is not an admin or does not exist.");
        }
      } catch (error) {
        console.error("Error connecting to Phantom:", error);
      }
    } else {
      console.error("Phantom wallet is not available. Please install Phantom and try again.");
    }
  };

  return (
    <div className="admin-connect">
      <img
        className="admin-connect-image"
        src="https://firebasestorage.googleapis.com/v0/b/third--space.appspot.com/o/ImageWebSite%2F3s-logo.png?alt=media&token=8a69bcce-2e9f-463e-8cba-f4c2fec1a904"
        alt="thirdspace logo"
      />
      <button className="admin-connect-button" onClick={handleLoginWithPhantom}>
        Connect
      </button>
    </div>
  );
};

export default ConnectAdmin;
