import React, { useState, useContext } from "react";

import NavBar from "../components/website/GenericComponents/NavBar";
import ComingSoon from "../components/website/GenericComponents/ComingSoon";
import { db, getDocs, collection } from "../firebaseConfig";
// import { AuthContext } from "../hooks/AuthProvide";
export default function Builder() {
  

  const [hasStepData, setHasStepData] = useState(false); // State to track if stepData is available
  const [hasWalletData, setHasWalletData] = useState(false);
  // const { isLoggedIn, walletId, setIsLoggedIn, setWalletId } = useContext(AuthContext);

  // console.log(walletId);
  // // localStorage.clear();
  // // sessionStorage.clear(); 
  // // Clearing the entire session storage
  // const checkWalletData = async () => {
  //   const userAccount = sessionStorage.getItem("userAccount");
  //   if (userAccount) {
  //     const docRef = collection(db, 'projects', userAccount, 'projectData');
  //     const docSnap = await getDocs(docRef);
  //     if (!docSnap.empty) { // Check if the snapshot is not empty
  //       setHasWalletData(true);
  //       let userData = [];
  //       docSnap.forEach((doc) => {
  //         userData.push(doc.data());
  //       });
  //       console.log(userData);
  //       if (userData.length > 0) { // Check if userData is present
  //         setHasStepData(true);
  //       }
  //       // navigate("/dashboard"); // Redirect to dashboard if wallet data exists
  //     } else {
  //       setHasWalletData(false);
  //     }
  //     setWalletId([userAccount]);
  //   }
  // };
  return (
    <>
      <NavBar
        // checkWalletData={checkWalletData}
        hasWalletData={hasWalletData}
        // walletId={walletId}
        // setWalletId={setWalletId}

      />
      <ComingSoon />
    </>
  );
}